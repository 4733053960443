/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;800&display=swap'); */
 body {
    margin: 0;
}
html {
    scroll-behavior: smooth;
}
/* fonts */
@font-face {
    font-family: 'lato';
    src: local('lato'),
        url(../app/assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'open-sans';
    src: local('open-sans'),
        url(../app/assets/fonts/OpenSans/OpenSans-Regular.ttf)
            format('truetype');
}
@font-face {
    font-family: 'playfair';
    src: local('playfair'),
        url(../app/assets/fonts/PlayFair/PlayfairDisplay-Regular.ttf)
            format('truetype');
}
@font-face {
    font-family: 'playfair-bold';
    src: local('playfair'),
        url(../app/assets/fonts/PlayFair/PlayfairDisplay-ExtraBold.ttf)
            format('truetype');
}
